import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import LaptopIcon from '@mui/icons-material/Laptop'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import { useMediaQuery } from 'react-responsive'

type Props = {
  devices: 'phone' | 'laptop'
  onChangeDevices: (device: 'phone' | 'laptop') => void
}

export const ProjectTypeButtons = ({ devices, onChangeDevices }: Props) => {
  const isMobile = useMediaQuery({ maxWidth: 768 })

  const handleChangeDevices = (
    event: React.MouseEvent<HTMLElement>,
    newDevice: string,
  ) => {
    if (!newDevice) return
    onChangeDevices(newDevice as 'phone' | 'laptop')
  }

  return (
    <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2">
      <ToggleButtonGroup
        value={devices}
        onChange={handleChangeDevices}
        exclusive
        aria-label="device"
        size={isMobile ? 'small' : 'medium'}
      >
        <ToggleButton
          value="phone"
          aria-label="phone"
          sx={{
            backgroundColor: 'white',
            color: 'black',
            cursor: 'pointer',
            transition: 'all 0.2s ease-in-out',
            boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
            zIndex: devices !== 'phone' ? 10 : 5, // ✅ 선택되지 않은 버튼의 z-index를 높게 설정
            '&:hover': {
              backgroundColor: '#f0f0f0',
              transform: devices !== 'phone' ? 'scale(1.1)' : 'none',
              boxShadow: '0px 4px 10px rgba(0,0,0,0.3)',
            },
            '&.Mui-selected': {
              backgroundColor: 'grey',
              color: 'white',
              transform: 'none',
              zIndex: 1, // ✅ 선택된 버튼의 z-index를 낮게 설정
              '&:hover': {
                backgroundColor: '#6b6b6b',
                transform: 'none',
              },
            },
          }}
        >
          <PhoneAndroidIcon fontSize="medium" />
        </ToggleButton>

        <ToggleButton
          value="laptop"
          aria-label="laptop"
          sx={{
            backgroundColor: 'white',
            color: 'black',
            cursor: 'pointer',
            transition: 'all 0.2s ease-in-out',
            boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
            zIndex: devices !== 'laptop' ? 10 : 5, // ✅ 선택되지 않은 버튼의 z-index를 높게 설정
            '&:hover': {
              backgroundColor: '#f0f0f0',
              transform: devices !== 'laptop' ? 'scale(1.1)' : 'none',
              boxShadow: '0px 4px 10px rgba(0,0,0,0.3)',
            },
            '&.Mui-selected': {
              backgroundColor: 'grey',
              color: 'white',
              transform: 'none',
              zIndex: 1, // ✅ 선택된 버튼의 z-index를 낮게 설정
              '&:hover': {
                backgroundColor: '#6b6b6b',
                transform: 'none',
              },
            },
          }}
        >
          <LaptopIcon fontSize="medium" />
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  )
}
