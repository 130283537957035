import { HighlightText } from '../../common/components/HightlightSpan'

export const AboutMe = () => {
  const personalInfo = [
    { label: '이름', value: '한승우' },
    { label: '이메일', value: 'hsoooo2341@gamil.com' },
    { label: '연락처', value: '+82-10-7299-2341' },
    { label: '학력', value: '한양대학교 컴퓨터소프트웨어학부 (4학년 재학)' },
  ]

  return (
    <div className="h-full bg-black flex justify-center items-center">
      <div className="flex flex-col justify-center items-center w-[80vw] max-w-[1000px]">
        <img
          src={`${process.env.REACT_APP_S3_URL}/about.jpeg`}
          alt="profile"
          className="rounded-full w-[200px] h-[200px] sm:w-[300px] sm:h-[300px] object-cover shadow-lg mb-4"
        />
        <div className="bg-white sm:p-8 p-5 shadow rounded-lg w-full">
          <div className="text-2xl sm:text-4xl mb-4 font-extrabold">
            About me 🙂
          </div>
          <div className="text-sm sm:text-base mb-4 font-regular border-l-2 sm:border-l-4 border-blue-500 pl-4 flex flex-col space-y-1">
            <HighlightText
              text="🤔 트래픽이 들어오고 나가는 A to Z까지 모든 부분을 알아야 최적화된 제품을 만들 수 있다고 생각합니다. 이를 위해 웹 프론트엔드, 모바일, 백엔드, 인프라 등 특정 분야에 국한되지 않고, 필요한 다양한 기술과 도구를 익히고 활용하는 것을 중요하게 여기고 있습니다."
              highlights={['A to Z', '다양한 기술', '도구']}
            />
            <HighlightText
              text="📞 다양한 사람들과 소통하는 것을 즐기며, 지속적으로 상호작용하여 더 나은 결과물을 만들어내는 것을 좋아합니다."
              highlights={['소통', '교류']}
            />
            <HighlightText
              text="🤝 한번 시작한 일은 끝까지 완수하는 것을 중요하게 생각하며, 문제가 발생했을 때 끈기있게 대처하여 해결하는 것을 좋아합니다."
              highlights={['끝까지', '해결']}
            />
            <HighlightText
              text="🤯 결국 개발은 존재하는 문제를 해결하는 수단이라고 생각합니다. 그렇기에 문제해결사가 되기 위해 주관과 넓은 시각을 기르기위해 노력하고 있습니다."
              highlights={['문제해결사', '주관', '시각']}
            />
          </div>
          <ul className="w-full">
            {personalInfo.map((info) => (
              <li
                key={info.label}
                className="flex flex-row justify-between text-xs sm:text-lg py-1"
              >
                <span className="font-semibold">{info.label}:</span>
                <span>{info.value}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
