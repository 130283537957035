import { ImageList, ImageListItem } from '@mui/material'
import { ProjectInfo } from './utils'

type Props = {
  projectInfo: ProjectInfo
  onClose?: () => void
}

export const ProjectImageList = ({ projectInfo, onClose }: Props) => {
  return (
    <div
      className="h-full w-full flex justify-center items-center"
      onClick={onClose}
    >
      <div className="max-w-[80vw]">
        <ImageList variant="standard" cols={3} gap={8}>
          {Array.from({ length: projectInfo.front.imageSize }).map(
            (_, index) => (
              <ImageListItem key={index}>
                <img
                  srcSet={`${process.env.REACT_APP_S3_URL}/${projectInfo.id}/${index}.png`}
                  src={`${process.env.REACT_APP_S3_URL}/${projectInfo.id}/${index}.png`}
                  alt={projectInfo.id}
                  loading="lazy"
                  style={{
                    maxWidth: '400px',
                    maxHeight: '400px',
                    objectFit: 'contain',
                    borderRadius: '8px',
                  }}
                />
              </ImageListItem>
            ),
          )}
        </ImageList>
      </div>
    </div>
  )
}
